import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (!this.isPreview) {
      this.element.classList.add('uk-animation-fade')
    }
  }

  disconnect() {
    this.element.classList.remove('uk-animation-fade')
  }

  get isPreview() {
    return document.documentElement.hasAttribute('data-turbolinks-preview')
  }
}
